.clock-container {
  margin-bottom: 48px;
}

.clock-title {
  font-size: 40px;
  line-height: 1.6;
  color: #fff;
  margin-bottom: 16px;
}

$timer-animation-timing: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$timer-animation-duration: 500ms;

@keyframes fold {
  0% { transform: rotateX(0deg); }
  100% { transform: rotateX(-180deg); }
}
@keyframes unfold {
  0% { transform: rotateX(180deg); }
  100% { transform: rotateX(0deg); }
}

.countdown-timer {
  display: flex;

  * {
    box-sizing: border-box;
  }

  span {
    pointer-events: none;
  }
}

.countdown-timer__unit {
  margin-right: 16px;

  &:first-child {
    margin-right: 32px;

    span, .countdown-timer__unit_label {
      color: #01e777;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.countdown-timer__unit_label {
  font-size: 16px;
  color: #fff;
  opacity: 0.6;
  line-height: 1;
  text-transform: lowercase;
  text-align: center;
  height: 20px;
  overflow: hidden;
  margin-top: 8px;
  position: relative;
  background-color: #000;
}

.countdown-timer__cards_container {
  display: flex;
}

.countdown-timer__card {
  font-size: 56px;
  font-weight: 700;
  position: relative;
  display: block;
  height: 64px;
  width: 64px;

  &:first-child {
    margin-right: 2px;
  }
}

.countdown-timer__card--upper,
.countdown-timer__card--lower,
.countdown-timer__card--flip {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
}

.countdown-timer__card--upper {
  align-items: flex-end;
  background: #111;
  border-radius: 8px 8px 0 0;

  span {
    transform: translateY(50%);
  }
}

.countdown-timer__card--lower {
  align-items: flex-start;
  background: #272727;
  border-top: 1px solid #000;
  border-radius: 0 0 8px 8px;

  span {
    transform: translateY(-50%);
  }
}

.countdown-timer__card--flip {
  position: absolute;
  left: 0;
  backface-visibility: hidden;

  &.fold {
    top: 0;
    align-items: flex-end;
    transform-origin: 50% 100%;
    transform: rotateX(0deg);
    animation: fold $timer-animation-duration $timer-animation-timing;
    transform-style: preserve-3d;

    span {
      transform: translateY(50%);
    }
  }

  &.unfold {
    top: 50%;
    align-items: flex-start;
    transform-origin: 50% 0;
    transform: rotateX(180deg);
    animation: unfold $timer-animation-duration $timer-animation-timing;
    transform-style: preserve-3d;

    span {
      transform: translateY(-50%);
    }
  }
}

@media all and (max-width: 900px) {
  .clock-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    order: -5;
    margin-bottom: 16px;
  }

  .clock-title {
    font-size: 16px;
    margin-bottom: 8px;
  }
}

@media all and (max-height: 960px) and (orientation: landscape) {
  .clock-title {
    font-size: 40px;
  }
}

@media all and (max-height: 800px) and (orientation: landscape) {
  .clock-title {
    font-size: 40px;
  }
}

@media all and (max-height: 780px) and (orientation: landscape) {
  .clock-title {
    font-size: 40px;
  }
}

@media all and (max-width: 780px) {
  .countdown-timer__card {
    font-size: 28px;
    width: 36px;
    height: 36px;
  }

  .countdown-timer__unit_label {
    font-size: 10px;
    height: 13px;
  }

  .countdown-timer__unit {
    margin-right: 8px;

    &:first-child {
        margin-right: 16px;
    }
  }
}

@media (max-width: 460px) {
  .countdown-timer__card {
      font-size: 24px;
      height: 32px;
      width: 32px;
  }

  .countdown-timer__unit_label {
      font-size: 8px;
      height: 10px;
      margin-top: 2px;
  }

  .clock-container {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 16px;
      margin-bottom: 16px;
  }

  .clock-title {
      font-size: 8px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.13;
      margin-bottom: 10px;
      margin-right: 12px;
  }

  .countdown-timer__unit:first-child {
    margin-right: 8px;
  }
}

@media all and (max-width: 340px) {
  .countdown-timer__card {
    font-size: 20px;
    height: 28px;
    width: 28px;
  }
}
